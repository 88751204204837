import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Text } from '@abyss/web/ui/Text';
import { Tooltip } from '@abyss/web/ui/Tooltip';

import {
  HeadingWrapper,
  popupContainerDataCardTextStyles,
  providerNameDataCardTextStyles,
  providerNameToolTipStyles,
} from './DataCard.style';

type Props = {
  popupContainer;
  sectionTypeDerived;
  name;
  handleOnHeadingBlur;
  handleHoverOnCard;
  providerId;
};

export const NameHeading = ({
  popupContainer,
  sectionTypeDerived,
  name,
  handleOnHeadingBlur,
  handleHoverOnCard,
  providerId,
}: Props) => (
  <HeadingWrapper
    alignLayout="left"
    css={
      popupContainer
        ? {
            padding: '0px',
            justifyContent: 'center',
          }
        : ''
    }
    data-auto-testid={`data-card-heading-${sectionTypeDerived}`}
    data-testid={`data-card-heading-${sectionTypeDerived}`}
    css={popupContainer ? {
      padding: '0px',
      justifyContent: 'center',
     } : ''}
    onBlur={handleOnHeadingBlur}
    onFocus={handleHoverOnCard}
  >
    <Tooltip
      asChild={false}
      content={name}
      css={
        popupContainer
          ? popupContainerDataCardTextStyles
          : providerNameToolTipStyles
      }
      position="top"
      positionOffset={8}
    >
      <Text
        color="$info1"
        css={
          popupContainer
            ? popupContainerDataCardTextStyles
            : providerNameDataCardTextStyles
        }
        data-auto-testid={`data-card-detail-button-${sectionTypeDerived}`.replace(
          / /g,
          '-'
        )}
        data-testid={`data-card-detail-button-${sectionTypeDerived}`.replace(
          / /g,
          '-'
        )}
        fontWeight="$bold"
        size={popupContainer ? '$sm' : '$lg'}
      >
        {name}
      </Text>
    </Tooltip>
    {!popupContainer && (
      <IconMaterial
        className="arrow_forward"
        css={{
          width: '24px',
          height: '12px',
        }}
        data-auto-testid={`data-card-arrow-forward-icon-${sectionTypeDerived}-${providerId}`}
        data-testid={`data-card-arrow-forward-icon-${sectionTypeDerived}-${providerId}`}
        icon="arrow_forward_ios"
      />
    )}
  </HeadingWrapper>
);
