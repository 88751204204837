import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Button } from '@abyss/web/ui/Button';
import { Drawer } from '@abyss/web/ui/Drawer';
import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { phoneOnly } from '../../ConstantsStyles';
import {
  ButtonContainer,
  ContentContainer,
  PopoverModalContainer,
  buttonStyled,
  iconStyle,
  isModalContainerDrawer,
} from './ProviderLocationsDetails.style';

type Props = {
  popoverContent?: string;
  popoverTitle?: string;
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
};

export const ModalContainer = ({
  popoverContent,
  popoverTitle,
  isOpen,
  setIsOpen,
}: Props) => {
  const { t } = useTranslation();
  const phoneScreen = useMediaQuery(phoneOnly);

  const handleCloseModal = () => setIsOpen(false);
  const popoverTestId = popoverTitle?.toLowerCase()?.split(' ')?.join('-');
  return (
    <React.Fragment>
      <IconMaterial
        css={iconStyle}
        data-testid={popoverTestId}
        icon="info"
        onClick={() => setIsOpen(!isOpen)}
        size={20}
        variant="outlined"
      />
      {isOpen ? (
        <>
          <PopoverModalContainer
            data-testid={`${popoverTestId}`}
            isOpen={isOpen && !phoneScreen}
            onClose={handleCloseModal}
            size={572}
            title={popoverTitle}
          >
            <Flex css={{ justifyContent: 'flex-end' }}>
              <ContentContainer data-testid={`${popoverTestId}-content`}>
                {popoverContent}
              </ContentContainer>
              <ButtonContainer>
                <Button
                  css={buttonStyled}
                  data-testid={popoverTestId}
                  onClick={() => setIsOpen(false)}
                  size="$sm"
                >
                  {t('Close')}
                </Button>
              </ButtonContainer>
            </Flex>
          </PopoverModalContainer>

          <Drawer
            css={isModalContainerDrawer}
            data-testid={popoverTestId}
            isOpen={isOpen && phoneScreen}
            onClose={() => setIsOpen(false)}
            position="bottom"
            size="200"
            title={popoverTitle}
          >
            <Flex css={{ justifyContent: 'flex-end' }}>
              {popoverContent}
              <div style={{ paddingTop: '16px' }}>
                <Button
                  css={buttonStyled}
                  data-testid={popoverTestId}
                  onClick={() => setIsOpen(false)}
                  size="$sm"
                >
                  {t('Close')}
                </Button>
              </div>
            </Flex>
          </Drawer>
        </>
      ) : null}
    </React.Fragment>
  );
};
